export const testimonials = [
  { 'headline': 'British Museum',
    'details' :'I love this app. I used to go to the BM all the time and wander around looking at all the objects. Many of those favourite objects are in this app, plus a load more i was fascinated to read about. Anyone interested in ancient history or human interest around the world will love this app.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum Guide',
    'name': 'Purchased at Amazon',
    'museum': 'BritishMuseum',
  },

  { 'headline': 'Overall, this app is really handy',
    'details' :'We chose this app over the audio guide and learned a lot more than we would have on our own. I encourage you to try this app, it has good maps, descriptions, and helps you to find the very best between the overwhelming amount of art.',
    'appstore': 'Google Playstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'Louvre Guide App',
    'name': 'Andras P',
    'museum': 'Louvre',
  },
  { 'headline': 'Five stars because I can\'t give it ten stars ...',
    'details' :'If you only buy ONE museum app in your life, this is surely one of the top five greatest museums in the world, so you can\'t go wrong with it. Not only is it a great museum, this is an extremely well made app, with excellent pictures and scholarly explanations of the art.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'Louvre Guide App',
    'name': 'MysterEO (Panama City)',
    'museum': 'Louvre',
  },
  { 'headline': 'Wonderful app... Download it even if you\'re not into art..you will be after you see this...beautiful!!!',
    'details' :'I downloaded the free version of this, but really enjoyed what parts of the museum I got to see. The beautiful marble sculptures are awe inspiring, along with the Mona Lisa. I\'ve added the Louvre to my bucket list now because of this app!',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'Louvre Guide App',
    'name': 'Rob',
    'museum': 'Louvre',
  },
  { 'headline': 'Your very own museum for a few dollars',
    'details' :'This is a nice cross section of what the museum has to offer. If you doubt you\'ll ever go to the British Museum, this is a good way to see it in privacy and comfort of your home. I like museums. It that\'s you, too. Spend a few bucks and "own" your own museum.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum App',
    'name': ' Kindle Customer (Austin, Texas)',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'Wonderful App!',
    'details' :'I work at the British Museum and have used the app many times to guide visitors around the museum and to give them information about the objects on display. Highly recommend this app',
    'appstore': 'Apple Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum App',
    'name': 'Les E',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'Excellent!',
    'details' :'I am going to the natural history museum Monday so this app really helped me get to know what it\'s like. I was really impressed by the amazing graphics and photography. I really appreciate this app and would like to thank the creators. Also I would strongly recommend this to anyone looking for a great app.',
    'appstore': 'Apple Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'NHM London Guide',
    'name': 'Lucy',
    'museum': 'NHM',
  },
  { 'headline': 'Five Stars!',
    'details' :'Super work and very useful for my teaching. Thank you.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'National Gallery Guide',
    'name': 'D Prior',
    'museum': 'NationalGallery',
  },
  { 'headline': 'Fantastic',
    'details' :'Having visited Musee D\'Orsay a few years ago, it is a pleasure to be able now do a virtual browsing of all the artwork.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'Musee d\'Orsay Guide',
    'name': 'BlueRing',
    'museum': 'Orsay',
  },
  { 'headline': 'Interesting and packed with information ',
    'details' :'Found app very interesting and full of great information. I definitely need to see the British museum!! Especially the Gregorian section - the app supplies a Great Map.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum Guide',
    'name': 'Gemini Carrick',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'British Museum Map and Info! ',
    'details' :'Good map of museum with many items of importance highlighted. Includes history of the world in 100 museum artifacts with notes',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum Guide',
    'name': 'Gary B Cooper',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'Very good! ',
    'details' :'I have been to the museum many times as I live in the London area. To me it is very useful to identify many objects for future visits and recognize others from previous ones. Sometimes you just pass objects without stopping as the museum is so large that you get tired quite easily from all the information you are trying to store in your brain. If you have never been there, then it may not have the amount of information that you might think it should have but it will help you to plan your visit for a better experience.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum Guide',
    'name': 'Chaly',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'Great App',
    'details' :'I\'ll probably never get to the BRITISH Museum again, so this app lets me couch-tour. I like it for spare moments.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'British Museum Guide',
    'name': 'Patricia Jarecki',
    'museum': 'BritishMuseum',
  },
  { 'headline': 'A treasure trove of art information',
    'details' :'First of all, this is the National Gallery on Trafalgar Square in London, not the National Gallery of Art in Washington, DC. If you\'re going to the gallery, the app would be a great aid in planning the visit to maximize how much you could see in the time you have. And if you\'re not planning a visit, the tremendous collection of European art described in the app could give many hours of enjoyment for an art lover.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'National Gallery',
    'name': 'J. Chambers (Georgia, US)',
    'museum': 'NationalGallery',
  },
  { 'headline': 'A perfect virtual tour of a subset of the museum',
    'details' :'Louvre Guide is a virtual tour of a subset of the museum. The Louvre is huge and this guide provides a few different tours based on timeframe for being in Paris. It portrays the most important pieces of the museum so you can plan your tour effectively. The Louvre Guide provides an excellent description of the art objects. Perfect. Highly Recommend.',
    'appstore': 'Amazon Appstore',
    'caption': 'Verified Amazon Purchase',
    'app': 'Louvre Guide App',
    'name': ' Smokey Bear (Brewster, NY USA) ',
    'museum': 'Louvre',
  },
];
