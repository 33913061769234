import React from 'react';
import Img from "gatsby-image";
import withStyles from '@material-ui/styles/withStyles';
import Hidden from "@material-ui/core/Hidden";
import Typography from '../atoms/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Getmuseuminfo from '../data/getmuseuminfo'
import {getGlobal} from 'reactn'

const styles = theme => ({
  card: {
    display:'flex',
    alignItems: 'center',
    height: 250,
    backgroundColor: 'rgb(251,233,231, 0.4)',
  },
  cardMedia: {
    borderRadius: 5,
    padding: 5,
    minHeight: 130,
    minWidth: 130,
    height: 130,
    width: 130,
    boxShadow: '0 10px 6px -6px #777',
    margin: 10,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
    backgroundColor: theme.palette.primary.background,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  caption: {
    fontStyle: 'italic'
  },
  cardcontent: {
    display:'flex',
    alignItems:'center',
    height: '25%',
  },
  cardheader: {
    height: '75%',
    paddingTop: 40,
  },
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.xlight,
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },

  image: {
    height: 75,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    left: -130,
    opacity: 0.1
  },
});


function Testimony(props) {
  const { classes, testimonial } = props;
  const {museumimageinfo} = getGlobal()

  const getstoreicon = (store) =>{
    switch (store) {
      case 'Amazon Appstore': return museumimageinfo.amazonlogo.childImageSharp.fluid;
      case 'Google Playstore': return museumimageinfo.googlelogo.childImageSharp.fluid;
      case 'Apple Appstore': return museumimageinfo.applelogo.childImageSharp.fluid;
      default: return museumimageinfo.applelogo.childImageSharp.fluid
    }
  }

  return (
            <Card  className={classes.card}>
                    <Hidden smDown>
                      <Img className={classes.cardMedia} fluid={Getmuseuminfo(testimonial.museum).fluidimage} />
                    </Hidden>
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'100%'}}>
                    <CardHeader className={classes.cardheader} subheader={testimonial.details}
                    subheaderTypographyProps={{variant: 'caption', style:{fontWeight: 600}}}/>
                  <CardContent className={classes.cardcontent}>
                    <div style={{height: 50, width: 50}}>
                    <Img fluid={getstoreicon(testimonial.appstore)} alt='appstore' title='Recommendation'/>
                    </div>
                    <Typography variant='caption'>
                      {testimonial.name}<br/><span style={{fontStyle: 'italic', fontSize: 11}}> (Review given for Museum Buddy {testimonial.app} on the {testimonial.appstore})</span>
                    </Typography>
                    </CardContent>
                    </div>

                  </Card>
            );
  }

export default withStyles(styles)(Testimony);
