import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image"
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import {getGlobal} from 'reactn'

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.xlight,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },

});

function VusiemUSP(props) {
  const { classes } = props;
  const {museumimageinfo} = getGlobal();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Img fluid={museumimageinfo.toursicon.childImageSharp.fluid} alt="Tours icon"
                imgStyle={{objectFit: 'contain'}}
                title='Guided Tours'
                className={classes.image}/>
              <Typography variant="h6" className={classes.title}>
                Self-Guided Tours
              </Typography>
              <Typography variant="h5">
                {'Customized Tours to fit within your schedule. '}
                {'Ensure you do not miss out on the must-see artefacts during your visit.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Img fluid={museumimageinfo.informationicon.childImageSharp.fluid} title="Informative"
                imgStyle={{objectFit: 'contain'}}
                alt='Information Icon'
                className={classes.image}/>
              <Typography variant="h6" className={classes.title}>
                Informative Commentary
              </Typography>
              <Typography variant="h5">
                {'Well researched and informative commentaries. '}
                {'Enjoy reading it at your pace and leisure.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Img fluid={museumimageinfo.awardicon.childImageSharp.fluid} title="Award-winning apps"
                imgStyle={{objectFit: 'contain'}}
                alt='Award Icon'
                className={classes.image}/>

              <Typography variant="h6" className={classes.title}>
                Award-winning Apps
              </Typography>
              <Typography variant="h5">
                {'Our award-winning Apps are guaranteed to make your '}
                {'museum day out enjoyable.'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

VusiemUSP.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VusiemUSP);
