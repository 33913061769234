import React from "react";
import {graphql} from 'gatsby'
import { Router } from "@reach/router"
import CssBaseline from "@material-ui/core/CssBaseline";
import Hero from "../components/Hero.js";
import VusiemUSP from "../components/VusiemUSP"
import Awardrecos from "../components/Awardrecos";
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import AppsGrid from "../components/AppsGrid"
import ShuffleArray from '../atoms/Shufflearray'
import * as testimonials from '../data/Testimonials'
import {setGlobal} from 'reactn'
import {handleLocation, isValidLocation, getCurrentLocation} from '../utils/checkValidLocation';
import {LinearProgress} from '@material-ui/core'
import Shutdown from '../components/Shutdown'

export default function HomePage(props) {

  {/*React.useEffect(() => {
    let currentOrigin = window?.location?.origin || 'vusiem'
    if (currentOrigin.toLowerCase().includes('vusiem')||currentOrigin.toLowerCase().includes('museum-buddy')) {
    let currentLocation = getCurrentLocation()
    if (currentLocation?.location) {
      setLocation(currentLocation.location);
      setLoading(false);
    } else {
    var requestOptions = {
      method: 'GET',
    };
    
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=3db745ec903945059d33e698d06ffd17", requestOptions)
      .then(response => response.json())
      .then(result => {handleLocation({locationCode: result?.country?.iso_code}); 
      if (result?.country?.iso_code !== 'DE') {setLocation(result?.country?.iso_code)}; 
      setLoading(false)})
      .catch(error => { handleLocation({locationCode: 'US'}); setLocation('US'); setLoading(false)});

    }} else {setLocation('US'); setLoading(false)}
  }, [])
  */}

  const testimonies = ShuffleArray(testimonials.testimonials)
  setGlobal({
      museumimageinfo: props.data,
  });

  return (
    <>
        <CssBaseline />
        <Layout >
        <SEO title="Museum Tour Guide Apps from Museum Buddy" type='review' testimonies={testimonies} />
        <main>
          <Hero />
          <VusiemUSP />
          <AppsGrid />
          <Awardrecos testimonies={testimonies}/>
          </main>
        </Layout>
      
    </>
  )
}

export const query = graphql`
query imagesetQuery {
britishmuseum: file(relativePath: {eq: "BritishMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
louvre: file(relativePath: {eq: "LouvreMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
nationalgallery: file(relativePath: {eq: "NationalGallery.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
nhm: file(relativePath: {eq: "NaturalHistoryMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
orsay: file(relativePath: {eq: "MuseeOrsay.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
orangerie: file(relativePath: {eq: "orangeriewaterlilies.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
rodin: file(relativePath: {eq: "RodinMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
neues: file(relativePath: {eq: "NeuesMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
pergamon: file(relativePath: {eq: "PergamonMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
altegalerie: file(relativePath: {eq: "AlteGalerie.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
capitoline: file(relativePath: {eq: "CapitolineMuseum.jpg"}) {
childImageSharp {
  fluid(maxWidth: 800, webpQuality: 90) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
herobackground: file(relativePath: {eq: "museumhorse.jpg"}) {
 childImageSharp {
     fluid(maxWidth: 2500, webpQuality: 100) {
       ...GatsbyImageSharpFluid_withWebp
     }
   }
}
herobackground1: file(relativePath: {eq: "elginmarbles.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 }
 herobackground2: file(relativePath: {eq: "nhmwhale.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 }
 herobackground3: file(relativePath: {eq: "generalmuseum.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 }
 herobackground4: file(relativePath: {eq: "hunter.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 }
 herobackground5: file(relativePath: {eq: "ngallery.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 }
 herobackground6: file(relativePath: {eq: "louvre1.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 } 
 herobackground7: file(relativePath: {eq: "louvre2.jpg"}) {
  childImageSharp {
      fluid(maxWidth: 2500, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
 } 
applebadge: file(relativePath: {eq: "Appleappstorebadge.png"}) {
childImageSharp {
  fluid(maxWidth: 200, webpQuality: 100) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
amazonbadge: file(relativePath: {eq: "amazonappstore.png"}) {
childImageSharp {
  fluid(maxWidth: 200) {
    ...GatsbyImageSharpFluid
  }
}
}
googlebadge: file(relativePath: {eq: "GooglePlayBadge.png"}) {
childImageSharp {
  fluid(maxWidth: 200,) {
    ...GatsbyImageSharpFluid_withWebp
  }
}
}
amazonlogo: file(relativePath: {eq: "AmazonAppsLogo.png"}) {
  childImageSharp {
    fluid(maxWidth: 100, webpQuality: 90) {
      ...GatsbyImageSharpFluid
    }
  }
}
googlelogo: file(relativePath: {eq: "PlayStoreLogo.png"}) {
  childImageSharp {
    fluid(maxWidth: 100, webpQuality: 90) {
      ...GatsbyImageSharpFluid
    }
  }
}
applelogo: file(relativePath: {eq: "AppStoreLogo.png"}) {
  childImageSharp {
    fluid(maxWidth: 100, webpQuality: 90) {
      ...GatsbyImageSharpFluid
    }
  }
}
vusiemlogo: file(relativePath: {eq: "VusiemLogoMain.png"}) {
  childImageSharp {
    fluid(maxWidth: 100, webpQuality: 90) {
      ...GatsbyImageSharpFluid
    }
  }
}
toursicon: file(relativePath: {eq: "guide.png"}) {
  childImageSharp {
    fluid(maxWidth: 200) {
      ...GatsbyImageSharpFluid
    }
  }
}
informationicon: file(relativePath: {eq: "smartphone.png"}) {
  childImageSharp {
    fluid(maxWidth: 200) {
      ...GatsbyImageSharpFluid
    }
  }
}
awardicon: file(relativePath: {eq: "award.png"}) {
  childImageSharp {
    fluid(maxWidth: 200,) {
      ...GatsbyImageSharpFluid
    }
  }
}
}
`
