import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import {isMobile, isAndroid} from "react-device-detect";
import Getmuseuminfo from '../data/getmuseuminfo'

import BackgroundImage from 'gatsby-background-image';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
      border: '4px solid white',
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  backgroundimage:{
    height: '100%',
    width: '100%',
    backgroundSize: 'cover'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  menuLink: {
    textDecoration: 'none'
  },
});

function AppsGrid(props) {
  const { classes } = props;
  const museumsgrid = ['BritishMuseum', 'NationalGallery', 'NHM',  'Orsay', 'Louvre','Orangerie'];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        Covering leading museums in Europe
      </Typography>
      <div className={classes.images}>
        {museumsgrid.map(museumname => {
          let museuminfo = Getmuseuminfo(museumname)

          return (

          <ButtonBase
            key={museuminfo.title}
            className={classes.imageWrapper}
            style={{
              width: museuminfo.width,
            }}
            href={museuminfo.url?museuminfo.url:(isMobile?(isAndroid?museuminfo.androidurl:museuminfo.ioSurl):museuminfo.amazonurl)}
          >
          <BackgroundImage
                    className={classes.backgroundimage}
                    fluid={museuminfo.fluidimage}
                  >
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {museuminfo.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </BackgroundImage>
          </ButtonBase>

        )})}
      </div>
    </Container>
  );
}

AppsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppsGrid);
