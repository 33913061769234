import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import withStyles from '@material-ui/styles/withStyles';
import {isMobile } from "react-device-detect";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import MobileStepper from "@material-ui/core/MobileStepper";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from "@material-ui/core/IconButton";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Testimony from '../components/Testimony'
import BackgroundImage from 'gatsby-background-image';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.xlight,
  },
  herobackground: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1))`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.9,
    zIndex: 1
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
    zIndex: 2
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 150,
    width: 150,
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: theme.palette.primary.dark,
  },
  innerbox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: theme.palette.primary.xlight,

  },
  cardMedia: {
    height: "200px"
  },
  recobox: {
    backgroundColor: theme.palette.primary.xlight,
    opacity: 0.95,

  },
  stepper: {
    backgroundColor: 'transparent'
  },
  image: {
    height: '100%',
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  awardtitle: {
    fontColor: theme.palette.primary.dark,
    alignItems: 'center',
    textAlign: 'center',

  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    left: -130,
    opacity: 0.1
  },
});

function Awardrecos(props) {
  const { classes, testimonies} = props;

  const [stepreco, setStepreco] = React.useState(0);

  const handleNext = () => {setStepreco(stepreco+1)};
 const handleBack = () => {setStepreco(stepreco-1)};

 const imageset = useStaticQuery(graphql`
   query awardlogoquery {
     background: file(relativePath: {eq: "wavylines.png"}) {
      childImageSharp {
          fluid(maxWidth: 2000, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    },
 award: file(relativePath: {eq: "MKDA16_Gold_MobileApp.jpg"}) {
   childImageSharp {
     fluid(maxWidth: 150, webpQuality: 80) {
       ...GatsbyImageSharpFluid_withWebp
     }
   }
 }
}
 `);

  return (
    <section className={classes.root}>

      <BackgroundImage
                Tag="section"
                className={classes.herobackground}
                fluid={imageset.background.childImageSharp.fluid}
                alt="curvy lines"
                title='Background wave'
                loading='lazy'
              >
      <Container className={classes.container}>

      <Grid container spacing={5} justify='space-evenly'>
          {/*<Grid item xs={12} md={4}>
            <Grid container direction={isMobile?'row':'column'} alignItems='center' justify='center'>
              <Grid item>
            <div className={classes.box}>
              <Img fluid={imageset.award.childImageSharp.fluid} alt="MKDA Best Mobile App Award"
                title='MKDA Best App Award'
                imgStyle={{objectFit: 'contain'}}
                className={classes.innerbox}/>
            </div>
            </Grid>
            <Grid item>
          <div className={classes.box}>
            <div className={classes.innerbox}>
            <Typography variant="subtitle2" className={classes.awardtitle}>
              Awarded<br/>
              Best Mobile App
              at MKDA'16
            </Typography>
            </div>
          </div>
          </Grid>
            </Grid>
  </Grid>*/}
          <Grid item xs={12} md={8}>

          <AutoPlaySwipeableViews
            className={classes.recobox}
          axis={"x"}
          index={stepreco}
          onChangeIndex={setStepreco}
          enableMouseEvents
          interval = {5000}
        >
          {testimonies.map((testimonial, index) => {
            return (
              <div key={index}>
                {Math.abs(stepreco - index) <= 2 ? (
                  <Testimony testimonial={testimonial}/>
                ) : null}
              </div>
            );
          })}
        </AutoPlaySwipeableViews>

        <MobileStepper
          steps={testimonies.length}
          position="static"
          activeStep={stepreco}
          className={classes.stepper}
          nextButton={
            <IconButton
              size="medium"
              aria-label = 'Next'
              onClick={handleNext}
              disabled={stepreco === testimonies.length - 1}
            >
              <NavigateNextIcon/>
            </IconButton>
          }
          backButton={
            <IconButton
              aria-label = 'Previous'
              size="medium"
              onClick={handleBack}
              disabled={stepreco === 0}
            >
              <NavigateBeforeIcon/>
            </IconButton>
          }
        />
          </Grid>

        </Grid>
      </Container>
    </BackgroundImage>
    </section>
  );
}

Awardrecos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Awardrecos);
