import React from "react";
import Img from "gatsby-image"
import Typography from '../atoms/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { OutboundLink } from "gatsby-plugin-gtag"
import BackgroundImage from 'gatsby-background-image';
import {getGlobal} from 'reactn'
import { makeStyles } from "@material-ui/core/styles";

export default function Hero() {
  const {museumimageinfo} = getGlobal()
  const useStyles = makeStyles(theme => ({
    herobackground: {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
      backgroundColor: theme.palette.primary.background,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: '80vh',
      height: '100vh',
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(8, 0, 6),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
      position: 'relative',
      minHeight: '80vh',
      height: '100vh',
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      //transform: 'scale(1.1)',
      //transition: 'all 5s'
    },
    heroButtons: {
      marginTop: theme.spacing(4)
    },
    title: {
      color: '#fbfcfc',

    },
    titlepaper: {
      padding: theme.spacing(3, 5),
      backgroundColor: theme.palette.grey.dark,
      opacity: 0.8,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    smalltitle: {
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      textTransform: 'none'
    },
    linkstyle: {
      margin: 0,
      padding: 0
    },
    menuLink: {
      backgroundColor: 'transparent',
      textDecoration: 'none'
    },

  }));

  const classes = useStyles();
  
  const randomNum = Math.floor(Math.random() * (8 - 1)) + 1;

  const getBackgroundImage = (num)=> {
  switch (num) {
    case 1: return museumimageinfo.herobackground1;
    case 2: return museumimageinfo.herobackground2;
    case 3: return museumimageinfo.herobackground3;
    case 4: return museumimageinfo.herobackground4;
    case 5: return museumimageinfo.herobackground5;
    case 6: return museumimageinfo.herobackground6;
    case 7: return museumimageinfo.herobackground7;
    default:return museumimageinfo.herobackground5;
  }
};
let backgroundImage = getBackgroundImage(randomNum).childImageSharp.fluid;

  return (
    <BackgroundImage
              Tag="section"
              className={classes.herobackground}
              fluid={backgroundImage}
            >
            <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Paper className={classes.titlepaper}>
        <Typography
          component="h1"
          variant="h3"
          className={classes.title}
          align="center"
          gutterBottom
        >
          Museum Buddy 
        </Typography>
        <Typography variant="h5" align="center" className={classes.smalltitle} paragraph>
          Your companion App for a day out at the Museum
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center" alignItems='center'>
            <Grid item sm={4} xs={12} style={{display:'flex', justifyContent:'center'}}>
              <OutboundLink className={classes.linkstyle} underline="none" href={'https://apps.apple.com/gb/developer/id551275215'}>
                <div style={{height: '100%', width: 120}}>
                  <Img fluid={museumimageinfo.applebadge.childImageSharp.fluid}  imgStyle={{objectFit: 'contain'}} alt='Apple appstore Logo' title='Download at Apple appstore' />
                </div>

            </OutboundLink>
  </Grid>
            <Grid item sm={4} xs={12} style={{display:'flex', justifyContent:'center'}}>

              <OutboundLink className={classes.linkstyle} underline="none" href={'https://play.google.com/store/apps/developer?id=Museum+Buddy'}>
              <div style={{height: '100%', width: 120}}>
                <Img fluid={museumimageinfo.googlebadge.childImageSharp.fluid}  imgStyle={{objectFit: 'contain'}}  alt='Google Playstore Logo' title='Download on Google Play'/>
              </div>

              </OutboundLink>

            </Grid>
            <Grid item sm={4} xs={12} style={{display:'flex', justifyContent:'center'}}>
              <OutboundLink className={classes.linkstyle} underline="none" href={'https://www.amazon.com/s?i=mobile-apps&rh=p_4%3AMuseum+Buddy&search-type=ss'}>


              <div style={{height: '100%', width: 120}}>
                <Img fluid={museumimageinfo.amazonbadge.childImageSharp.fluid}  imgStyle={{objectFit: 'contain'}}  alt='Amazon appstore Logo' title='Download from Amazon appstore'/>
              </div>
            </OutboundLink>


            </Grid>
          </Grid>
        </div>
      </Paper>
      </Container>
      </div>
      </BackgroundImage>
  );
}
